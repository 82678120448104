
































































 import { ImagePreview,Toast } from "vant"
 import ScrollView from "@/components/ShareComponent/ScrollView.vue"
 import { ShopSucStore } from "@/store/modules/Shop/ShopSuc"
 import { Vue,Component,Ref } from "vue-property-decorator"

 interface ShopSucComType<T=string>{
     DefaultIcon :T;

     handlePreviewImg():void;
     handleCopyPhone(str:string):void;
 }
//
 @Component({ name:"ShopSuc_Com",components:{ ScrollView } })
 export default class ShopSucCom extends Vue implements ShopSucComType{
     DefaultIcon = require("$icon/Shop/TestShop.png");
     CopyIcon = require("$icon/Shop/Copy.png");
     handlePreviewImg(){
         ImagePreview({
             images:[ this.GetData.image || this.DefaultIcon ],
         })
     }

     handleCopyPhone(str:string){
         if( !str.length )return;
         this.GetCopy.value = str;
         this.GetCopy.select()
         document.execCommand("copy")
         Toast({
            message:"复制成功",
            icon:"none",
            className:"ToastClassName"
        })
     }

     @Ref("copy")
     copyHtml!:HTMLInputElement;

     get GetCopy(){
         return this.copyHtml
     }

     get GetData(){
         return ShopSucStore.GetShopSuc
     }
 }
