



































































import { ImagePreview } from "vant"
import { Vue,Component,Prop,Watch,Model, Emit } from "vue-property-decorator"

 interface CommoditySucType<T=string>{
     ShopImg:T;
     RemoveIcon:T;
     AddIcon:T;
     handleAddClick():void;
     handleRemoveClick():void;
     handlePreview():void;
     handleClickShow():void;
 }

 @Component({ name:"CommoditySuc" })
 export default class CommoditySuc extends Vue implements CommoditySucType{
     ShopImg = require("$icon/Shop/TestShop.png");
     RemoveIcon = require("$icon/Shop/RemoveBtn.png");
     AddIcon = require("$icon/Shop/AddBtn.png");

     handleAddClick(){
         //
     }

     handleRemoveClick(){
         //
     }

     handlePreview(){
         ImagePreview({
             images:[ this.GetData.picture || this.ShopImg ]
         })
     }

     handleClickShow(){
         this.ChangeShow()
     }

     @Prop(Object)
     data!:any

     get GetData(){
         return this.data
     }

     @Prop(Object)
     other!:any

     get GetOther(){
         return this.other
     }

     @Prop(Boolean)
     show!:boolean

     get GetShow(){
         return this.show
     }

     @Emit("ChangeShow")
     ChangeShow(){
         return true
     }
 }
