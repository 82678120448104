










































































import { Notify,Toast } from "vant"
import ScrollX from "@/components/ShareComponent/ScrollViewX.vue"
import ShopCoupon from "@/components/ShopComponent/ShopSuc/ShopCoupon.vue"
import Stroage from "@/util/Storage"
import { ShopSucCouponType } from "@/Type/index"
import { ShopSucStore } from "@/store/modules/Shop/ShopSuc"
import { FindCoupon } from "@/Api/Shop/index"
import { Vue,Component,Emit, Watch } from "vue-property-decorator"

interface ShopHeadType<T=string>{
   loadingShow:boolean;
   userId:T|number;
   token:T;
   shopIcon:T;
   NoticeIcon:T;
   RightIcon:T;
   DownIcon:T;
   FullCoupon:T;
   FullBool:boolean;
   Show:boolean
   CouponList:ShopSucCouponType[];

   handleClickShow():void;
   handleGetShow(data:any):void;
   handleUpData():void;
   handleClickComment():void;
   FilterCoupon():any
   handleError(data:string):void;
}

@Component({ name:"ShopHead",components:{ ScrollX,ShopCoupon } })
export default class ShopHead extends Vue implements ShopHeadType{
   loadingShow = false
   userId:string|number = ""
   token = ""
   shopIcon = require("$icon/Shop/TestShop.png");
   NoticeIcon = require("$icon/Shop/StopNotice.png");
   RightIcon = require("$icon/Shop/Right.png");
   DownIcon = require("$icon/Shop/Down.png")
   FullCoupon = ""
   FullBool = false
   Show = false
   CouponList:ShopSucCouponType[] = []

   mounted() {
       this.userId = Stroage.GetData_ && Stroage.GetData_("userId")
       this.token = Stroage.GetData_ && Stroage.GetData_("token")
       this.FilterCoupon()
       this.handleUpData()
   }

   handleClickShow(){
       if( this.CouponList.length ){
           this.Show = true
       }else{
           Toast({
               message:"暂无优惠活动",
               icon:"none",
               className:"ToastClassName"
           })
       }
   }

   handleGetShow(data:any){
       this.Show = data
   }

   get GetData(){
       return ShopSucStore.GetShopSuc
   }

   handleClickComment(){
       this.PullClick()
   }

   FilterCoupon(){
       let Str = ""
       if( this.GetData.reducteList && this.GetData.reducteList?.length ){
           this.GetData.reducteList.forEach((item,index)=>{
               if( index ){
                   Str += ` | 满${ item.fullPrice }减${ item.reducePrice }`
               }else{
                   Str = `满${ item.fullPrice }减${ item.reducePrice }`
               }
           })
       }else{
           Str = '暂无满减活动'
           this.FullBool = true
       }
       this.FullCoupon = Str
   }

   handleUpData(){
       FindCoupon({
           userId:this.userId,
           token:this.token
       },{
           storeId:this.$route.query.id as string,
           pageNo:1,
           pageSize:100
       }).then( res=>{
           if( res ){
               this.CouponList = res.data
           }else{
               this.handleError( res.message.msg )
           }
       } )
   }

   handleError( data:string ){
       let str = ""
       if ( data.length ){
           str = data
       }else{
           str = "网络繁忙..."
       }
       Notify({
           message: str,
           color: 'white',
           background: "rgba(0,0,0,.6)",
       });
   }

   @Watch("GetData")
   handleChangeGetData(newVal:any){
       this.FilterCoupon()
   }

   @Emit("PullClick")
   PullClick(){
       return true
   }
}
