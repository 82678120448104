









































































































import { Notify } from "vant"
import ScrollView from "@/components/ShareComponent/ScrollView.vue"
import Stroage from "@/util/Storage"
import { ShopSucStore } from "@/store/modules/Shop/ShopSuc"
import { GetShopTypeList,GetShopComment } from "@/Api/Shop/index"
import { Vue,Component,Prop, Emit, Watch } from "vue-property-decorator"
import {keyArr} from "@/views/Money/AddAccount/Model";

interface CommentType<T=string>{
    [propsData:string]:any
}

interface CommodityType<T=string>{
    userId:T|number;
    token:T;
    loadingShow:boolean
    LeftTop:number;
    RightTop:number;
    ShopIcon:T;
    AddIcon:T;
    RemoveIcon:T;
    LeftIndex:number
    Chi:number
    ScrollList:Array<number>;
    TypeList:Array<any>;
    CommentData:CommentType;
    ActiveList:Array<any>;

    initData():void;
    handleRecurOrder():void;
    handleRemoveClick(key:T,data:any,index:number,e:any):void;
    handleAddClick(key:T,data:any,index:number,e:any):void;
    handleCommentClick(data:any,index:number):void;
    handleUpDataType():void;
    handleFitlerData(data:Array<any>):void;
    handleFilterList( data:Array<any> ):void;
    handleFilterComment():void
    handleComputed(data:Array<any>):void;
    handleLeftClick(index:number):void;
    handlePullClick(data:any):void;
    handleError(data:string):void;
}

@Component({ name:"Commodity",components:{ ScrollView } })
export default class Commodity extends Vue implements CommodityType{
    userId:string|number = ""
    token = ""
    loadingShow = false
    LeftTop = 0;
    RightTop = 0;
    ShopIcon = require("$icon/Shop/TestShop.png");
    AddIcon = require("$icon/Shop/AddBtn.png");
    RemoveIcon = require("$icon/Shop/RemoveBtn.png");
    LeftIndex = 0;
    Chi = 0;
    ScrollList = [ 720,1440,2160 ]
    TypeList:Array<any> = []
    CommentData:CommentType = {} // 商品数据
    ActiveList:Array<any> = [] // 选中的商品


    mounted() {
        this.userId = Stroage.GetData_ && Stroage.GetData_("userId")
        this.token = Stroage.GetData_ && Stroage.GetData_("token")
        // eslint-disable-next-line
        // @ts-ignore
        this.Chi = window.Cha
        // this.initData()
    }

    activated(){
        this.initData()
    }

    initData(){
        this.handleUpDataType()
    }

    handleCommentClick(data:any,index:number){
        this.PullClick({
            data,index
        })
    }

    handleRecurOrder(){ // 判断是否再来一单 recur
        if( "recur" in this.$route.query ){
           let time = setTimeout(()=>{
               let { CommentData } = this;
               let List:Array<any> = JSON.parse( this.$route.query.List as string )
               let KeyArr = Object.keys( CommentData )
               let ActiveList:Array<any> = []
               List.forEach( (ComItem:any,ComIndex:number)=>{
                   KeyArr.forEach( (item,index)=>{
                       CommentData[item].forEach( (itm:any,idx:number)=>{
                           if( itm.id == ComItem.productId ){
                               itm.Num = Number( ComItem.number )
                               itm.Key = item
                               ActiveList.push( itm )
                           }
                       } )
                   } )
               } )
               this.ActiveList = ActiveList
               clearTimeout(time)
           },100)
        }
    }

    handleAddClick(key:string,data:any,index:number,e:any){
        let List:Array<any> = this.ActiveList
        if( List.length ){
            List.forEach( (item,idx)=>{
               if( item.id == data.id ){
                   item.Num += 1
                   item.Key = key
                   item.index = index
               }else{
                   let Index = List.findIndex( a=>a.id === data.id )
                   if( Index < 0 ){
                       data.Num = 1
                       data.Key = key
                       data.index = index
                       List.push( data )
                   }
               }
           } )
        }else{
            data.Num = 1
            data.Key = key
            data.index = index
            List.push( data )
        }
        this.ActiveList = List
        e.preventDefault();
        e.stopPropagation();
    }

    handleRemoveClick(key:string,data:any,index:number,e:any){
        let List:Array<any> = this.ActiveList
        let Index = 0;
        let bool = false;
        List.forEach( (item,index)=>{
            if( item.id == data.id ){
                if( data.Num > 1 ){
                    item.Num -= 1
                }else{
                    item.Num = 0
                    Index = index;
                    bool = true
                }
            }
        } )
        if( bool ){
            this.CommentData[ key ][ index ].Num = 0
            List.splice( Index,1 )
        }
        this.ActiveList = List
        e.preventDefault();
        e.stopPropagation();
    }

    handleLeftClick(index:number){
        if( index ){
            this.RightTop = this.ScrollList[ index - 1 ]
        }else{
            this.RightTop =  0
        }
        this.LeftIndex = index
    }

    handlePullClick(data:any){
        let Num = Math.abs( data )
        let Index = 0;
        this.ScrollList.forEach( (item,index)=>{
            let num = item * this.Chi
            if( Num >= num ){
                Index = index + 1
            }
        } )
        this.LeftIndex = Index
    }

    handleUpDataType(){
        this.loadingShow = true
        GetShopTypeList({
            userId:this.userId,
            token:this.token
        },{
            storeId:this.$route.query.id as string
        }).then( res=>{
            console.log( res )
            if( res.message.code === "200" ){
                this.handleFitlerData( res.data.catalogList )
            }else{
                this.handleError( res.message.msg )
            }
        } )
    }

    handleFitlerData(data:Array<any>){
        if( !data.length ){ this.loadingShow = false; return; }
        let List:Array<any> = []
        let ListType:Array<any> = []; // 类型列表
        let CommentData:any = {}
        let KeyNum = 0;
        data.forEach( (item,index)=>{
            item.Key = "A"+index
            ListType.push(item)
            List.push(
                GetShopComment({
                   userId:this.userId,
                   token:this.token
               },{
                   catalogId:item.id
               })
            )
        } )
        new Promise( (resolve,reject)=>{
            Promise.all(List).then( res=>{
               this.handleComputed( res )
               res.forEach( (item,index)=>{
                   if( item.message.code === "200" ){
                       CommentData[`A${index}`] = this.handleFilterList(item.data.productList) || []
                       KeyNum++;
                   }
               } )
               resolve(true)
           } ).catch( cat=>cat)
        } ).then(res=>{
           if( data.length > KeyNum ){
               this.handleError("商品请求失败")
           }
           this.CommentData = CommentData
           this.TypeList = ListType;
           // 判断是否再来一单
           this.handleRecurOrder()
           // 初始化
           this.ActiveList = this.GetOnActiveList
            if ( this.ActiveList.length )this.handleFilterComment();
           let time = setTimeout(()=>{
               this.loadingShow = false
               clearTimeout( time )
           },200)
        })
    }

    handleFilterList( data:Array<any> ){
        if( !data.length )return [];
        let List:Array<any> = [];
        data.forEach((item,index)=>{
            item.Num = 0
            List.push(item)
        })
        return List
    }

    handleComputed(data:Array<any>){
        if( !data.length )return;
        let List:Array<number> = [];
        // 标题 68 内容 220px
        data.forEach( (item,index)=>{
            if( item.message.code === "200" ){
                let Num = 0
                if( index ){
                   Num = 68 + ( 220 * item.data.productList.length ) + List[index - 1]
                }else{
                   Num = 68 + ( 220 * item.data.productList.length )
                }
                List.push( Num )
            }
        } )
        this.ScrollList = List
    }

    handleFilterComment(){
        let { CommentData,ActiveList } = this;
        ActiveList.forEach( (item,index)=>{
            CommentData[ item.Key ].forEach( (itm:any,idx:number)=>{
                if( itm.id === item.id ){
                    this.CommentData[item.Key][idx].Num = item.Num
                }
            } )
        } )
    }

    handleFilterComList(){
        let { ActiveList,CommentData } = this;
        if( ActiveList.length <= 0 ){
            let time = setTimeout( ()=>{
               let KeyArr = Object.keys( CommentData )
               KeyArr.forEach( (item,index)=>{
                   CommentData[item] && CommentData[item].forEach( (itm:any,idx:number)=>{
                       this.CommentData[item][idx].Num = 0
                   } )
               } )
               clearTimeout( time )
            } )
        }
    }

    handleError( data:string ){
       let str = ""
       if ( data.length ){
           str = data
       }else{
           str = "网络繁忙..."
       }
       Notify({
           message: str,
           color: 'white',
           background: "rgba(0,0,0,.6)",
       });
   }

   @Emit("PullClick")
   PullClick(data:any){
       return data
   }

   @Watch("ActiveList",{ deep:true })
   handleChangeOnList(newVal:Array<any>){
       ShopSucStore.SetOnShopList( newVal )
       // 清空
       this.handleFilterComList()
       // 商品过滤
       this.handleFilterComment()
   }

   get GetOnActiveList(){
       return ShopSucStore.GetOnShopList
   }

   @Watch("GetOnActiveList",{ deep:true })
   handleChangeList(newVal:Array<any>){
       this.ActiveList = newVal
   }
}
