



















































import { Notify,Toast } from "vant"
import ScrollViewY from "@/components/ShareComponent/PullDownUp.vue"
import Stroage from "@/util/Storage"
import { ShopSucCouponType } from "@/Type/index"
import { GetShopCoupon } from "@/Api/Shop/index"
import { Vue,Component, Watch, Emit, Prop } from "vue-property-decorator"

interface ShopCouponType<T=string>{
    userId:T|number;
    token:T;
    cardBack:T;
    Show:boolean;

    handleChangeShow():void;
    handleFitler(str:string):void;
    handleGetCoupon(data:ShopSucCouponType):void;
    handleError(data:string):void;
}   

@Component({ name:"ShopCoupon",components:{ ScrollViewY } })
export default class ShopCoupon extends Vue implements ShopCouponType{
    userId:string|number = ""
    token = ""
    cardBack = require("$icon/Shop/CardBack.png")
    Show = false

    mounted() {
        this.userId = Stroage.GetData_ && Stroage.GetData_("userId")
        this.token = Stroage.GetData_ && Stroage.GetData_("token")
    }

    handleChangeShow(){
        this.PullShow( !this.Show )
    }

    handleFitler(str:string){
        if( str && str.length ){
            return str.split(" ")[0]
        }else{
            return str
        }
    }

    handleGetCoupon(data:ShopSucCouponType){
        GetShopCoupon({
            userId:this.userId,
            token:this.token
        },{
            couponId:data.id
        }).then(res=>{
            if( res.message.code === "200" ){
                Toast({
                   message:"领取成功",
                   icon:"none",
                   className:"ToastClassName"
               })
               this.PullShow( !this.Show )
            }else{
                this.handleError( res.message.msg )
            }
        })
    }

    handleError( data:string ){
       let str = ""
       if ( data.length ){
           str = data
       }else{
           str = "网络繁忙..."
       }
       Notify({
           message: str,
           color: 'white',
           background: "rgba(0,0,0,.6)",
       });            
   }


    @Emit("PullShow")
    PullShow(data:boolean){
        return data
    }

    @Prop(Boolean)
    show!:boolean

    get GetShow(){
       return this.show
    }

    @Watch("GetShow")
    ChangeShow(newVal:boolean){
        this.Show = newVal
    }


    @Prop(Array)
    data!:ShopSucCouponType[]

    get GetData(){
        return this.data
    }
}
