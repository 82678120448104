




















































import { Notify } from "vant"
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import HeadCom from "@/components/ShopComponent/ShopSuc/Head.vue"
import ContCom from "@/components/ShopComponent/ShopSuc/Commodity.vue"
import ShopCom from "@/components/ShopComponent/ShopSuc/ShopSuc_Com.vue"
import CommodtiySuc from "@/components/ShopComponent/ShopSuc/CommoditySuc.vue"
import FooterCom from "@/components/ShopComponent/ShopSuc/Footer.vue"
import Stroage from "@/util/Storage"
import { GetShopSuc } from "@/Api/Shop/index"
import { IndexStore } from "@/store/modules/Index"
import { ShopSucStore } from "@/store/modules/Shop/ShopSuc"
import { Vue,Component } from "vue-property-decorator"
import ZoomPage from "@/impView/PageSafety";

interface ShopSucType<T=string>{
   userId:T|number;
   token:T;
   loadingShow:boolean
   title:T;
   AppointList:T[]
   AppointIndex:number
   NavIndex:number;
   AppointShow:boolean
   CommentData:any;// 商品详情
   OtherData:any// 商品其他数据
   CommentShow:boolean;// 商品是否显示

   initData():void;
   handleBack(data?:boolean):void;
   handleClickNav():void;
   handleAppointToggle(index:number):void;
   handleUpData():void;
   handleCommentShow(data:any):void;
   handleError(data:string):void;
}

@Component({ name:"ShopSuc",components:{ HeadTop,HeadCom,FooterCom,ContCom,CommodtiySuc,ShopCom } })
export default class ShopSuc extends ZoomPage implements ShopSucType{
   userId:string|number = ""
   token = ""
   loadingShow = false
   title = ""
   AppointList = [ "外卖配送","预约自取餐" ]
   AppointIndex = 0
   NavIndex = 0;
   AppointShow = false
   CommentData:any = {}
   OtherData:any = {}
   CommentShow = false

   mounted() {
       this.userId = Stroage.GetData_ && Stroage.GetData_("userId")
       this.token = Stroage.GetData_ && Stroage.GetData_("token")
       // this.initData()
   }

    activated(){
        this.initData()
    }

    deactivated(){
        IndexStore.SetIncidentBool(true)
    }

    beforeDestroy(){
        IndexStore.SetIncidentBool(true)
    }

   initData(){
       this.title = this.$route.query.title as string
       if( Number( IndexStore.GetShopState.type ) == 0 ){
           this.AppointShow = true
       }else{
           this.AppointShow = false
       }
       //店铺详情缓存
       if(  JSON.stringify( ShopSucStore.GetShopSuc ) === "{}" && ShopSucStore.GetShopSuc.id != this.$route.query.id ){
           this.handleUpData()
       }
       document.onscroll = function(e){
           e.preventDefault()
           e.stopPropagation()
       }
       IndexStore.SetIncidentBool(false)
   }

   handleBack(data?:boolean){
       let { NavIndex } = this;
       if( NavIndex ){
           this.NavIndex = 0
       }else{
           this.$router.go(-1)
       }
   }

   handleClickNav(){
       this.NavIndex = 1
   }

   handleAppointToggle(index: number): void {
       if( index !== this.AppointIndex ){
           this.AppointIndex = index
       }
   }

   handleUpData(){
       this.loadingShow = true
       GetShopSuc({
           userId:this.userId,
           token:this.token
       },{
           id:this.$route.query.id as string
       }).then( (res:any)=>{
           this.loadingShow = false
           if( res.message.code === "200" ){
               ShopSucStore.SetShopSuc( res.data )
           }else{
               this.handleError( res.message.msg )
           }
       } )
   }

   handleCommentShow(data:any){
       this.CommentData = data.data
       this.OtherData = {
           index:data.index
       }
       this.$router.push({
           name:"commoditySuc",
           query:{
               data:JSON.stringify( data || {} ),
               shopData:JSON.stringify( ShopSucStore.GetShopSuc || {} )
           }
       })
       // this.CommentShow = true
   }

   handleChangeShow(){
       this.CommentShow = !this.CommentShow
   }

   handleError( data:string ){
       let str = ""
       if ( data.length ){
           str = data
       }else{
           str = "网络繁忙..."
       }
       Notify({
           message: str,
           color: 'white',
           background: "rgba(0,0,0,.6)",
       });
   }
}
