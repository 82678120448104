
import Axios from "../Config/shop"
import {
    AllUpDataType,
    PayUpDataType,
    MyOrderListType,
    ChangeOrderState, RecommendProductType, GetShopFeedbackListType,
} from "./interfaceType"

interface HeadType<T=string>{
    userId:T|number;
    token:T;
}

interface BodyType<T=number>{
    pageNo:T;
    pageSize:T;
}

// 广场
export function AllShop( headers:HeadType,body:AllUpDataType ):Promise<any>{
    return (
        Axios({
            url:"/app/store/storeList",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 店铺详情
export function GetShopSuc( headers:HeadType,body:{ id:number|string } ):Promise<any>{
    return (
        Axios({
            url:"/app/store/storeDetail",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 店铺分类
export function GetShopTypeList( headers:HeadType,body:{ storeId:number|string } ):Promise<any>{
    return (
        Axios({
            url:"/app/store/storeCatalog",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 请求商品
export function GetShopComment( headers:HeadType,body:{ catalogId:number|string } ):Promise<any>{
    return (
        Axios({
            url:"/app/store/productList",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 创建订单
export function CreateOrder( headers:HeadType,body:PayUpDataType ):Promise<any>{
    return (
        Axios({
            url:"/app/order/createOrder",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 请求支付参数
export function PayBefore( headers:HeadType,body:{ orderId:string|number } ):Promise<any>{
    return (
        Axios({
            url:"/app/weixin/weixinApiPay",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 支付后回调
export function PayAfter( headers:HeadType,body:{ orderId:string|number } ):Promise<any>{
    return (
        Axios({
            url:"/app/weixin/callBack",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 我的订单列表
export function MyOrderList ( headers:HeadType,body:MyOrderListType ):Promise<any>{
    return (
        Axios({
            url:"/app/order/myOrder",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 订单详情
export function MyOrderSuc( headers:HeadType,body:{ orderId:string,pageNo?:number,pageSize?:number } ):Promise<any>{
    return (
        Axios({
            url:"/app/shoppingOrder/queryOrderDetail",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 修改订单信息状态
export function BackOrder( headers:HeadType,body:ChangeOrderState ):Promise<any>{
    return (
        Axios({
            url:"/app/order/updateOrder",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 退款
export function BackPay( headers:HeadType,body:{ orderId:string } ):Promise<any>{
    return (
        Axios({
            url:"/app/weixin/refund",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 商品推荐
export function RecommendProduct( headers:HeadType,body:RecommendProductType ):Promise<any>{
    return (
        Axios({
            url:"/app/store/queryShopRecommend",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 查询异常订单
export function GetShopFeedbackList( headers:HeadType,body:GetShopFeedbackListType ):Promise<any>{
    return (
        Axios({
            url:"/app/order/queryFuzzyOrder",
            method:"POST",
            headers,
            data:body
        })
    )
}


/**
 *  店铺优惠卷
*/
interface FindCouponType<T=string> extends BodyType<number>{
    storeId:T|number;
}
// 店铺查询
export function FindCoupon( headers:HeadType,body:FindCouponType ):Promise<any>{
    return (
        Axios({
            url:"/app/coupon/queryCoupon",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 领取店铺优惠卷
export function GetShopCoupon( headers:HeadType,body:{ couponId:string|number; } ):Promise<any>{
    return (
        Axios({
            url:"/app/coupon/receiveShopCoupon",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 领取店铺优惠卷列表
export function GetShopCouponList( headers:HeadType,body:{ storeId:string|number;status:number; } ):Promise<any>{
    return (
        Axios({
            url:"/app/coupon/userShopCouponList",
            method:"POST",
            headers,
            data:body
        })
    )
}

