
















































import ScrollView from "@/components/ShareComponent/ScrollView.vue"
import { ShopSucStore } from "@/store/modules/Shop/ShopSuc"
import { Vue,Component, Watch, Prop, Emit } from "vue-property-decorator"

interface CommodtiyListType<T=string>{
    RemoveIcon:T;
    AddIcon:T;
    TestIcon:T;
    Show:boolean;
    List:Array<any>

    handleClear():void;
    handleShow():void;
    handleRemove(data:any,index:number):void;
    handleAdd(data:any,index:number):void;
}

@Component({ name:"CommodtiyList",components:{ ScrollView } })
export default class CommodtiyList extends Vue implements CommodtiyListType{
    RemoveIcon = require("$icon/Shop/RemoveBtn.png");
    AddIcon = require("$icon/Shop/AddBtn.png")
    TestIcon = require("$icon/Shop/TestShop.png");
    Show = false;
    List:Array<any> = []

    handleClear(){
        ShopSucStore.SetOnShopList( [] )
        this.PullShow()
    }

    handleShow(){
        this.PullShow()
    }

    handleRemove(data:any,index:number){
        let bool = false;
        let Index = 0;
        let List:Array<any> = this.List
        List.forEach( (item,index)=>{
            if( item.id == data.id ){
                if( data.Num > 1 ){
                    data.Num -= 1
                }else{
                    item.Num = 0
                    bool = true
                    Index = index
                }
            }
        } )
        if( bool ){
            this.List = this.List.splice(Index,1)
        }
        if( List.length <= 0 ){
            this.PullShow()
        }
        this.List = List
    }

    handleAdd(data:any,index:number){
        let List:Array<any> = this.List
        List.forEach( (item,index)=>{
            if( item.id == data.id ){
                item.Num += 1
            }
        } )
        this.List = List
    }

    @Watch("List")
    handleList(newVal:Array<any>){
        ShopSucStore.SetOnShopList(newVal)
    }

    get GetOnList(){
        return ShopSucStore.GetOnShopList
    }

    @Prop(Boolean)
    show!:boolean

    get GetShow(){
        return this.show
    }

    @Watch("GetShow")
    handleChangeShow(newVal:boolean){
        this.Show = newVal
        if( newVal ){
            this.List = this.GetOnList
        }
    }

    @Emit("PullShow")
    PullShow(){
        return true
    }
}
