






































import { Toast } from "vant"
import CommodtiyList from "@/components/ShopComponent/ShopSuc/CommodtiyList.vue"
import { ShopSucStore } from "@/store/modules/Shop/ShopSuc"
import { UserStore } from "@/store/modules/User"
import { Vue,Component, Prop, Watch } from "vue-property-decorator"

interface FooterType<T=string>{
    EatIcon:T;
    List:Array<any>
    Show:boolean;
    Money:number|T;
    Price:number|T;
    Num:number;
    PayBool:boolean;

    handleComputedMoney():void;
    handleClickShow():void;
    handleChangeShow():void;
    handleGoPay():void;
}

@Component({ name:"Footer",components:{ CommodtiyList } })
export default class Footer extends Vue implements FooterType{
    EatIcon = require("$icon/Shop/Ect.png");
    List:Array<any> = []
    Show = false
    Money:string|number = 0;
    Price:string|number = 0;
    Num = 0;
    PayBool = false;

    mounted() {
       this.List = this.GetOnList
       this.handleComputedMoney()
    }

    handleGoPay(){
        if( !this.PayBool ){
            Toast({
               message:"请选择商品",
               icon:"none",
               className:"ToastClassName"
            })
            return;
        }
        let Data = JSON.stringify( this.List )
        let show = false;
        if( this.GetAppoint ){
            show = true
           //  if( this.GetAppointIndex ){
           //      show = true
           //  }else{
           //      show = false
           //  }
        }
        UserStore.SetSelAddress({})
        this.$router.push({
            path:"/buyList",
            query:{
                data:Data,
                appoint:show + "",
                id:this.$route.query.id as string,
                ShopData:JSON.stringify( this.GetData )
            }
        })
    }

    handleClickShow(){
        if( this.List.length ){
            this.Show = !this.Show
        }
    }

    handleChangeShow(){
        this.Show = !this.Show
    }

    handleComputedMoney(){
        if( this.List.length < 1){
            this.Num = 0
            this.PayBool = false
            this.Money = 0
            this.Price = 0
            return;
        }
        let Money = 0;
        let Price = 0;
        let Num = 0;
        this.List.forEach( (item,index)=>{
            Money += (item.nowPrice * item.Num)
            Price += (item.price * item.Num)
            Num += item.Num
        } )
        this.Money = Number( ( Money + (Money? Number(this.GetData.deliverPrice) : 0 || 0) ).toFixed(2) );
        this.Price = Number( ( Price + (Money? Number(this.GetData.deliverPrice) : 0 || 0) ).toFixed(2) );
        this.Num = Num

        if( this.Money >= (Number(this.GetData.startPrice) || 0) ){
            this.PayBool = true
        }else{
            this.PayBool = false
        }

        // 店铺优惠
        let List = this.GetData.reducteList
        let CouponList:Array<any> = []
        if( List!.length ){
            List?.forEach( (item,index)=>{
                if( item.status == 0 && item.fullPrice ){
                    if( this.Money >= Number( item.fullPrice ) ){
                        CouponList.push( item )
                    }
                }
            } )
        }
        if( CouponList.length ){
            CouponList = CouponList.sort( (a,b)=> Number(b.fullPrice) - Number( a.fullPrice )  )
            this.Money -= ( Number( CouponList[0].reducePrice ) || 0 )
        }


    }

     handleFilterOnList(){
        let { List } = this;
        List.forEach( (item,index)=>{
            if( item.Num <= 0 ){
                List.splice( index,1 )
            }
        } )
        this.List = List
    }


    get GetOnList(){
        return ShopSucStore.GetOnShopList
    }
    @Watch("GetOnList",{ deep:true })
    handleChangeList(newVal:Array<any>){
        this.List = newVal
        this.handleComputedMoney()
    }

    get GetData(){
        return ShopSucStore.GetShopSuc
    }

    @Prop(Boolean)
    appoint!:boolean

    get GetAppoint(){
        return this.appoint
    }

    @Prop(Number)
    appointIndex!:number

    get GetAppointIndex(){
        return this.appointIndex
    }
}
